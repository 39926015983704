import React, { useState, useEffect } from 'react';
import questionsData from './questions.json';  // Import the JSON data
import { MdOutlineKeyboardBackspace } from "react-icons/md";

// Reusable QuestionCard Component
const QuestionCard = ({ question, options, currentQuestion, setCurrentQuestion, handleSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleNext = () => {
    if (currentQuestion < questionsData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  return (
    <div className="w-full p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">{question}</h2>
      <div className="space-y-4">
        {options.map((option, index) => (
          <div key={index} className="flex items-center">
            <input
              type="radio"
              id={`option-${index}`}
              name="option"
              value={option}
              checked={selectedOption === option}
              onChange={() => handleOptionChange(option)}
              className="mr-2"
            />
            <label htmlFor={`option-${index}`} className="text-lg">{option}</label>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-between">
        <button
          onClick={handlePrev}
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
        >
          Previous
        </button>
        {/* Conditionally render Next/Submit button */}
        {currentQuestion === questionsData.length - 1 ? (
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white py-2 px-4 rounded-md"
          >
            Submit
          </button>
        ) : (
          <button
            onClick={handleNext}
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

const Test = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1800);  // 30 minutes = 1800 seconds
  const currentData = questionsData[currentQuestion];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSubmit = () => {
    // Placeholder for handling form submission (e.g., calculate score, send results)
    alert('Quiz Submitted!');
  };

  useEffect(() => {
    // Start the timer when the component mounts
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(prevTime => prevTime - 1);
      } else {
        clearInterval(timer);  // Stop the timer when it reaches 0
        handleSubmit();  // Automatically submit the quiz when time runs out
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
    // We do NOT reset the timer here, because it's for the entire quiz
  }, [currentQuestion]);

  return (
    <div className="flex flex-col md:flex-row">
      {/* Hamburger Icon (Visible on small screens) */}
      <div className="md:hidden p-4 bg-gray-800">
        <button onClick={toggleSidebar} className="text-2xl text-white">
          {isSidebarOpen ? '✖' : '☰'} {/* Hamburger Icon */}
        </button>
      </div>

      {/* Sidebar (Visible on large screens or if opened on small screens) */}
      <div className={`md:w-1/4 bg-gray-800 text-white p-4 md:block ${isSidebarOpen ? 'block' : 'hidden md:block'}`}>
        <h1 className="text-2xl font-bold mb-8">Questions</h1>
        <div className="space-y-2 grid grid-cols-5 gap-2">
          {questionsData.map((question, index) => (
            <div key={question.id} className="cursor-pointer mt-2">
              <button
                onClick={() => setCurrentQuestion(index)}
                className={`w-12 h-12 text-center rounded-md ${
                  currentQuestion === index ? 'bg-blue-500' : 'bg-gray-700'
                }`}
              >
                {index + 1}
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Main Question Area */}
      <div className="flex-1 p-8 ">
        {/* Timer and Back Button */}
        <div className=" p-4 flex justify-between w-full md:w-auto">
          <button
            onClick={() => setCurrentQuestion(0)}  // Back to first question
            className="text-4xl "
          >
            <MdOutlineKeyboardBackspace />
          </button>
          <div className="flex items-center">
            <span className="text-lg font-semibold mr-2">Time Left: {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}</span>
          </div>
        </div>

        {currentData ? (
          <QuestionCard
            question={currentData.question}
            options={currentData.options}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            handleSubmit={handleSubmit}
          />
        ) : (
          <p>No questions available</p>
        )}
      </div>
    </div>
  );
};

export default Test;